// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-accounting-system-js": () => import("./../../../src/pages/projects/accounting-system.js" /* webpackChunkName: "component---src-pages-projects-accounting-system-js" */),
  "component---src-pages-projects-company-mobile-app-js": () => import("./../../../src/pages/projects/company-mobile-app.js" /* webpackChunkName: "component---src-pages-projects-company-mobile-app-js" */),
  "component---src-pages-projects-company-website-js": () => import("./../../../src/pages/projects/company-website.js" /* webpackChunkName: "component---src-pages-projects-company-website-js" */),
  "component---src-pages-projects-document-management-system-js": () => import("./../../../src/pages/projects/document-management-system.js" /* webpackChunkName: "component---src-pages-projects-document-management-system-js" */),
  "component---src-pages-projects-export-document-system-js": () => import("./../../../src/pages/projects/export-document-system.js" /* webpackChunkName: "component---src-pages-projects-export-document-system-js" */),
  "component---src-pages-projects-inventory-system-js": () => import("./../../../src/pages/projects/inventory-system.js" /* webpackChunkName: "component---src-pages-projects-inventory-system-js" */),
  "component---src-pages-projects-project-management-mobile-app-js": () => import("./../../../src/pages/projects/project-management-mobile-app.js" /* webpackChunkName: "component---src-pages-projects-project-management-mobile-app-js" */),
  "component---src-pages-projects-purchase-order-management-system-js": () => import("./../../../src/pages/projects/purchase-order-management-system.js" /* webpackChunkName: "component---src-pages-projects-purchase-order-management-system-js" */)
}

